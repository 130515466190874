import React, { useState, useEffect, useRef } from "react";

import { makeStyles, createStyles } from "@material-ui/styles";
import {
  Theme,
  IconButton,
  useMediaQuery,
  FormControl,
  OutlinedInput,
  InputAdornment,
  DialogTitle,
  Dialog,
  DialogContent,
  Paper,
  Slide,
  MenuItem,
  Menu,
  Badge,
  CircularProgress,
} from "@material-ui/core";

import { useTheme } from '@material-ui/core/styles';

import SendIcon from '@material-ui/icons/SendOutlined';
import IconClode from '@material-ui/icons/Close';
import IconMore from '@material-ui/icons/MoreVert';
import { TransitionProps } from "@material-ui/core/transitions";
import { LeaseOffer, ChatMessage } from "leasemojo-common";
import { useDispatch, useSelector } from "react-redux";

import { actions as offerActions } from '../../services/offers';
import { actions as chatActions } from '../../services/chat';
import { RootState } from "../../store";

import MessageItem from './MessageItem';

const useStyles = makeStyles((theme: Theme) =>
  createStyles(
    {
      root: {
        height: '100vh',
      },
      title: {
        position: 'fixed',
        [theme.breakpoints.up('sm')]: {
          position: 'absolute',
        },
        top: 0,
        left: 0,
        width: '100%',
        borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
        textAlign: 'center',
      },
      messageList: {
        flex: 1,
        overflow: 'auto',
        padding: theme.spacing(2),
        display: 'flex',
        flexDirection: 'column',
      },
      divBox: {
        bakdgdounrColor: 'red',
        border: '1px solid #eee',
        borderTop: '1px solid #222',
        borderBottom: '1px solid #222',
        borderTopLeftRadius: 10,
        borderTopRightRadius: 10,
        borderBottomColor: 'ref '
      },
      bottom: {
        position: 'fixed',
        [theme.breakpoints.up('sm')]: {
          position: 'absolute',
        },
        bottom: 0,
        left: 0,
        width: '100%',
        padding: theme.spacing(2),
        paddingBottom: `calc(16px + env(safe-area-inset-bottom, 0px))`,
      },
      messageInput: {
        width: '100%',
      },
      header: {
        ...theme.mixins.toolbar,
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
        borderBottom: '1px solid #eee',
      },
      closeIcon: {
        position: 'absolute',
        left: theme.spacing(1),
        top: '50%',
        transform: 'translateY(-50%)',
      },
      moreIcon: {
        position: 'absolute',
        right: theme.spacing(1),
        top: '50%',
        transform: 'translateY(-50%)',
      },
      content: {
        display: 'flex',
        flexDirection: 'column',
        padding: 0,
        paddingTop: 64,
        paddingBottom: 70,
      },
      chatLoading: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flex: 1,
      }
    }
  ),
);

interface Props {
  open: boolean;
  onClose: () => void;
  offer: LeaseOffer;
}

const Transition = React.forwardRef<unknown, TransitionProps>(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const ChatMessages: React.FC<Props> = ({ open, onClose, offer }) => {
  const classes = useStyles();
  const theme = useTheme();
  const [ anchorEl, setAnchorEl ] = useState<null | HTMLElement>(null);

  const [ message, setMessage ] = useState('');

  const onMessageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setMessage(event.target.value);
  };

  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const dispatch = useDispatch();

  const state = useSelector((state: RootState) => ({
    chat: state.chat,
  }));

  const scrollEl = useRef<HTMLDivElement>(null);

  const messages: ChatMessage[] = offer.id && state.chat.chats[ offer.id ] ? state.chat.chats[ offer.id ].messages : [];

  useEffect(() => {
    requestAnimationFrame(() => {
      if (scrollEl && scrollEl.current && open) {
        scrollEl.current.scrollTop = scrollEl.current.scrollHeight;
      }
    });
  }, [ messages.length, open ])


  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    if (open && offer.id) {
      dispatch(chatActions.setCurrentChat(offer.id));
      dispatch(chatActions.loadMessages(offer.id));
    }
    else if (!open) {
      dispatch(chatActions.setCurrentChat(null));
      if (offer.id) {
        dispatch(chatActions.resetUnseenMessages(offer.id));
      }
    }

  }, [ open ]);

  const openMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const closeMenu = () => {
    setAnchorEl(null);
  };

  const enableChat = () => {
    offer.id &&
      dispatch(offerActions.toggleChat(offer.id, true));

    closeMenu();
  }

  const disableChat = () => {
    offer.id &&
      dispatch(offerActions.toggleChat(offer.id, false));
    closeMenu();
  }

  const onSubmitForm = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    event.stopPropagation();
    if (offer.id) {
      dispatch(chatActions.sendMessage(offer.id, message))
      setMessage('');
    }
  };

  const canSendMessage = offer.chatAllowed || state.chat.loading;

  return (
    <Dialog
      open={open}
      onClose={onClose}
      classes={{
        paper: classes.root
      }}
      TransitionComponent={Transition}
      maxWidth="sm"
      fullWidth
      fullScreen={isMobile}
      scroll="paper"
    >
      <DialogTitle className={classes.title}>
        Messages
        <IconButton aria-label="close" className={classes.closeIcon} onClick={onClose}>
          <IconClode />
        </IconButton>
        <IconButton aria-label="chat-options" onClick={openMenu} className={classes.moreIcon}>
          <Badge badgeContent="" color="secondary" variant="dot" invisible={offer.chatAllowed}>
            <IconMore />
          </Badge>
        </IconButton>
      </DialogTitle>
      <DialogContent dividers className={classes.content}>
        <div className={classes.messageList} ref={scrollEl}>
          {
            state.chat.loading ? (
              <div className={classes.chatLoading}><CircularProgress /></div>
            ) : messages.map(item => <MessageItem data={item} key={item.id} />)
          }
        </div>
        <Paper className={classes.bottom} elevation={6}>
          <form onSubmit={onSubmitForm}>
            <FormControl variant="outlined" className={classes.messageInput} disabled={!canSendMessage}>
              <OutlinedInput
                placeholder="Send a message"
                labelWidth={0}
                multiline
                rowsMax={6}
                value={message}
                onChange={onMessageChange}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton color="secondary" disabled={!canSendMessage || message.length === 0} type="submit">
                      <SendIcon />
                    </IconButton>
                  </InputAdornment>
                }
              />
            </FormControl>
          </form>
        </Paper>
      </DialogContent>
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={closeMenu}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <MenuItem disabled={offer.chatAllowed} onClick={enableChat}>Enable Chat</MenuItem>
        <MenuItem disabled={!offer.chatAllowed} onClick={disableChat}>Disable Chat</MenuItem>
      </Menu>
    </Dialog >

  );
};

export default ChatMessages;