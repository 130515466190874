import React from "react"
import { Router } from "@reach/router"

import PrivateRoute from '../components/PrivateRoute';

import SignIn from '../app/SignIn/SignIn';
import MyOffers from '../app/MyOffers';
import MyAccount from '../app/MyAccount';
import Offer from '../app/Offer/Offer';

const App = () => (

  <Router>
    <SignIn path="/app/sign-in" />
    <PrivateRoute path="/app/my-offers" component={MyOffers} />
    <PrivateRoute path="/app/my-offers/:offerId" component={Offer} />
    <PrivateRoute path="/app/my-account" component={MyAccount} />
  </Router>

)

export default App
