import React, { useState } from "react";

import { makeStyles, createStyles } from "@material-ui/styles";
import { Theme, Button, Menu, MenuItem } from "@material-ui/core";
import { LeaseOfferPublic } from "leasemojo-common";
import { useDialog } from "../DialogProvider";

import find from 'lodash/find';


import { actions } from '../../services/inquiries';
import { useDispatch } from "react-redux";

const useStyles = makeStyles((theme: Theme) =>
  createStyles(
    {
      root: {
      },
      dealerName: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
      }
    }
  ),
);

interface Props {
  inquiryId: string;
  offers: LeaseOfferPublic[];
  winningOffer?: string;
  disabled?: boolean;
}

const PickWinnerOffer: React.FC<Props> = ({ offers, inquiryId, winningOffer, disabled }) => {
  const classes = useStyles();

  const [ anchorEl, setAnchorEl ] = useState<null | HTMLElement>(null);

  const dialog = useDialog();
  const dispatch = useDispatch();

  const open = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const close = () => {
    setAnchorEl(null);
  };

  const setWinningOffer = (offer: LeaseOfferPublic) => {
    close();
    dialog.confirm({
      title: `Please confirm`,
      subtitle: `You leased your new car from ${offer.dealerData?.name}?`,
      onConfirm: () => {
        dispatch(actions.setWinningOffer({
          offerId: offer.id,
          inquiryId
        }))
      }
    })
  }

  const winningOfferData = winningOffer ? find(offers, { id: winningOffer }) : null;

  return (
    <React.Fragment>
      <Button variant="contained" color="secondary" disableElevation onClick={open} disabled={disabled}>
        {
          winningOfferData ? `${winningOfferData.dealerData?.name}` : 'Pick a winning offer'
        }
      </Button>
      <Menu
        anchorEl={anchorEl}
        keepMounted
        getContentAnchorEl={null}
        open={Boolean(anchorEl)}
        onClose={close}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        {
          offers.map(offer => (
            <MenuItem key={offer.id} onClick={() => { setWinningOffer(offer) }}>
              Offer from <strong className={classes.dealerName}>{offer.dealerData?.name}</strong> for ${offer.monthlyPayment} a month
            </MenuItem>
          ))
        }
      </Menu>
    </React.Fragment>
  );
};

export default PickWinnerOffer;