import React, { useEffect } from "react";

import { makeStyles, createStyles } from "@material-ui/styles";
import {
  Theme,
  Typography,
  Toolbar,
  CircularProgress,
  Box,
  Button,
  Badge,
  ButtonBase,
  Link,
} from "@material-ui/core";

import Layout from '../../components/Layout';
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../store";
import { RouteComponentProps } from "@reach/router";

import find from 'lodash/find';

import { actions } from '../../services/offers';

import OfferDetails from './OfferDetails';
import ChatMessages from './ChatMessages';
import ContactDealerModal from './ContactDealerModal';
import DealerRatingModal from '../../components/DealerRatingModal/DealerRatingModal';
import { useQueryParam, BooleanParam, useQueryParams } from "use-query-params";

import Rating from '../../components/Rating';

const useStyles = makeStyles((theme: Theme) =>
  createStyles(
    {
      root: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'flex-start',
        padding: theme.spacing(0, 2)
      },
      toolbar: {
        borderBottom: '1px solid #eee',
        marginBottom: theme.spacing(4),
        marginTop: theme.spacing(2),
        paddingBottom: theme.spacing(1),

        flexDirection: 'column',
      },
      link: {
        textDecoration: 'underline',
        marginLeft: theme.spacing(1),
      },
      offerButtons: {
        width: '100%',
        maxWidth: 400,
      },
      spacer: {
        width: theme.spacing(2),
        height: theme.spacing(2),
      },
      ratingBox: {
        marginBottom: theme.spacing(4),
        borderRadius: 4,
        padding: theme.spacing(1, 2)
      },
      ratingSummary: {
        opacity: '1 !important',
      },
      offerCode: {
        fontSize: 18,
      },
      code: {
        letterSpacing: 2,
      },
      logo: {
        width: '100%',
        maxWidth: 250,
      },
      yelp: {
        width: 80,
        marginBottom: theme.spacing(1),
      },
      yelpLink: {
        textDecoration: 'underline',
      }
    }
  ),
);

interface Props extends RouteComponentProps {
  offerId: string;
}

const OfferPage: React.FC<Props> = ({ offerId }) => {
  const classes = useStyles();
  const state = useSelector((state: RootState) => {
    return {
      offers: state.offers,
      inquiries: state.inquiries,
    }
  });

  const [ chatOpen = false, setChatOpen ] = useQueryParam('chat', BooleanParam);
  const [ contactOpen = false, setContactOpen ] = useQueryParam('contact', BooleanParam);
  const [ ratingOpen, setRatingOpen ] = useQueryParams({
    rating: BooleanParam,
    submit: BooleanParam,

  });

  const openRating = () => {
    if (offer.dealerData?.yelpId) {
      window.open(`https://www.yelp.com/biz/${offer.dealerData.yelpId}`);
    }
    else {
      setRatingOpen({
        rating: true,
        submit: false,
      }, 'push');
    }
  }
  const openRatingSubmit = () => {
    if (offer.dealerData?.yelpId) {
      window.open(`https://www.yelp.com/biz/${offer.dealerData.yelpId}`);
    }
    else {
      setRatingOpen({
        rating: true,
        submit: true,
      }, 'push');
    }
  }

  const closeRating = () => {
    setRatingOpen({
      rating: false,
      submit: false,
    }, 'replace');
  }

  const closeChat = () => {
    setChatOpen(false, 'replace');
  }
  const openChat = () => {
    setChatOpen(true, 'pushIn');
  }

  const openContact = () => {
    setContactOpen(true, 'replace');
  }
  const closeContact = () => {
    setContactOpen(false, 'pushIn');
  }

  const offer = state.offers.offers[ offerId ] || null;
  const inquiry = offer ? find(state.inquiries.inquiries, { id: offer.inquiry }) : null;
  const dispatch = useDispatch();

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    dispatch(actions.loadOffer(offerId));
  }, [ offerId ]);

  const logoUrl = offer && Boolean(offer.dealerData?.logo) ? `${process.env.CDN_BASE_URL}/${offer.dealerData?.logo}` : null;

  return (
    <Layout>
      <div className={classes.root}>
        {state.offers.loading || !offer || !inquiry ? <Box mt={2}><CircularProgress /></Box> : (
          <React.Fragment>
            <Toolbar className={classes.toolbar}>
              {
                logoUrl ? <img src={logoUrl} alt={`${offer.dealerData?.name} Logo`} className={classes.logo} /> : null
              }
              <Typography variant="h4" align="center">
                {offer.dealerData?.name}
              </Typography>
              {
                offer.dealerData?.yelpId ? (
                  <ButtonBase className={classes.ratingBox} onClick={openRating}>
                    <Box display="flex" alignItems="center" flexDirection="column">
                      <Rating
                        value={offer.dealerData.yelpRating}
                        precise={true}
                        readonly />
                      <Typography variant="caption">{offer.dealerData?.yelpReviewCount} reviews on Yelp</Typography>
                      <img src="/assets/yelp-logo.png" alt="Yelp" className={classes.yelp} />
                    </Box>
                  </ButtonBase>
                ) : (
                    <ButtonBase className={classes.ratingBox} onClick={openRating}>
                      <Box display="flex" alignItems="center" flexDirection="column">
                        <Rating
                          value={offer.dealerData?.rating ?? 0}
                          precise={true}
                          readonly />
                        <Typography variant="caption">{offer.dealerData?.reviewCount} reviews</Typography>
                      </Box>
                    </ButtonBase>
                  )
              }


              <Typography paragraph>Lease offer</Typography>
              <Typography variant="h6" className={classes.offerCode}>Offer Code: <span className={classes.code}>{offer.offerCode}</span></Typography>
            </Toolbar>
            <Box display="flex" mt={1} mb={4} className={classes.offerButtons} flexDirection="column">
              <Badge badgeContent={offer.unseenMessagesUser} color="secondary">
                <Button color="primary" variant="contained" disableElevation onClick={openChat} fullWidth>
                  Chat with Dealer
                </Button>
              </Badge>
              <div className={classes.spacer} />
              <Button color="primary" variant="contained" disableElevation onClick={openContact}>Contact Dealer</Button>
              <div className={classes.spacer} />
              <Button color="primary" variant="contained" disableElevation onClick={openRatingSubmit}>Write a Review</Button>
            </Box>
            <OfferDetails offer={offer} inquiry={inquiry} />
            <ChatMessages open={chatOpen} onClose={closeChat} offer={offer} />
            {
              offer.dealerData ? <ContactDealerModal open={contactOpen} onClose={closeContact} dealer={offer.dealerData} /> : null
            }
            <DealerRatingModal open={Boolean(ratingOpen.rating)} onClose={closeRating} offer={offer} />

          </React.Fragment>
        )}
      </div>
    </Layout>
  );
};

export default OfferPage;