import React from "react";
import clsx from 'clsx';
import { makeStyles, createStyles } from "@material-ui/styles";
import { Theme, Avatar, Typography } from "@material-ui/core";
import { ChatMessage, timeAgo } from "leasemojo-common";
import { useSelector } from "react-redux";
import { RootState } from "../../store";



const useStyles = makeStyles((theme: Theme) =>
  createStyles(
    {
      root: {
        display: 'flex',
        marginBottom: theme.spacing(2),
      },
      rootLeft: {
        justifyContent: 'flex-start',
      },
      rootRight: {
        justifyContent: 'flex-end',
      },
      item: {
        display: 'flex',
        flexDirection: 'column',
      },
      message: {
        display: 'flex',
        flexDirection: 'column',
        '&.left': {
          alignItems: 'flex-start',
        },
        '&.right': {
          alignItems: 'flex-end',
        },
      },
      text: {
        padding: theme.spacing(1, 1.5),
        backgroundColor: '#eee',
        '&.right': {
          backgroundColor: theme.palette.divider,
          borderBottomRightRadius: 15,
          borderTopLeftRadius: 15,
          borderBottomLeftRadius: 15,
        },
        '&.left': {
          backgroundColor: theme.palette.secondary.main,
          borderBottomLeftRadius: 15,
          borderTopRightRadius: 15,
          borderBottomRightRadius: 15,
        }
      },
      spacer: {
        width: theme.spacing(1),
        height: theme.spacing(1),
      },
      meta: {
        display: 'flex',
        alignItems: 'center',
      }
    }
  ),
);

interface Props {
  data: ChatMessage
}

const MessageItem: React.FC<Props> = ({ data }) => {
  const classes = useStyles();
  const state = useSelector((state: RootState) => ({
    user: state.user.user,
    offers: state.offers.offers,
  }));

  const dealerName = state.offers[ data.offer ].dealerData?.name;
  const isLeft = data.from !== state.user?.id;


  return (
    <div className={clsx(classes.root, { [ classes.rootLeft ]: isLeft, [ classes.rootRight ]: !isLeft })}>
      <div className={classes.item}>
        <div className={clsx(classes.message, { 'left': isLeft, 'right': !isLeft })}>
          <div className={classes.meta}>
            <Typography variant="body2">{isLeft ? dealerName : 'Me'}</Typography>
          </div>
          <div className={(clsx(classes.text, { 'left': isLeft, 'right': !isLeft }))}>
            <Typography color="inherit">{data.message}</Typography>
            <Typography variant="caption">{timeAgo(data.createTime as number)}</Typography>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MessageItem;