import React from "react";
import { Link } from '@reach/router';

import { makeStyles, createStyles } from "@material-ui/styles";
import { Theme, Typography, Button, Box, IconButton } from "@material-ui/core";
import IconMore from "@material-ui/icons/ArrowForwardIos";
import { LeaseOfferPublic } from "leasemojo-common";

const useStyles = makeStyles((theme: Theme) =>
  createStyles(
    {
      root: {
        borderBottom: '1px solid #eee',

        '&:last-child': {
          borderBottom: 'none',
        }
      },
    }
  ),
);

interface Args {
  data: LeaseOfferPublic
}

const OfferItem: React.FC<Args> = ({ data }) => {
  const classes = useStyles();
  return (
    <Box display="flex" justifyContent="space-between" alignItems="center" className={classes.root}>
      <Box display="flex" justifyContent="space-between" alignItems="center" flex="1">
        <Typography variant="subtitle1">
          {data.dealerData?.name}
        </Typography>
        <Typography variant="body2">
          ${data.monthlyPayment}/month
      </Typography>
      </Box>
      <IconButton component={Link} to={`/app/my-offers/${data.id}`}>
        <IconMore fontSize="small"/>
      </IconButton>
    </Box>
  );
};

export default OfferItem;