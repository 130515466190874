import {ExteriorColor, InteriorColor} from '../cars';

export const getInteriorColorIndex = (id: number, colors: InteriorColor[]) => {
  for (let i = 0; i < colors.length; i++) {
    if (colors[ i ].id === id) {
      return i;
    }
  }
  return 0;
}

export const getExteriorColorIndex = (id: number, colors: ExteriorColor[]) => {
  for (let i = 0; i < colors.length; i++) {
    if (colors[ i ].id === id) {
      return i;
    }
  }
  return 0;
}