import React from "react";

import { makeStyles, createStyles } from "@material-ui/styles";
import { Theme, Box, Typography, Divider } from "@material-ui/core";
import { DealerReview, timeAgo } from "leasemojo-common";

import IconProfile from '@material-ui/icons/AccountCircle';

import Rating from '../Rating';

const useStyles = makeStyles((theme: Theme) =>
  createStyles(
    {
      root: {
        display: 'flex',
        flexDirection: 'column',
        marginBottom: theme.spacing(2),
      },
      spacer: {
        width: theme.spacing(1),
        height: theme.spacing(1),
      }
    }
  ),
);

interface Props {
  data: DealerReview;
}

const ReviewItem: React.FC<Props> = ({ data }) => {
  const classes = useStyles();
  const userName = data.userProfile.firstName ? `${data.userProfile.firstName} ${data.userProfile.lastName}` : 'Anonymous';

  return (
    <div className={classes.root}>
      <Box display="flex" alignItems="center">
        <IconProfile />
        <div className={classes.spacer} />
        <Typography variant="caption">{userName}</Typography>
        <div className={classes.spacer} />
        <Rating
          value={data.rating}
          precise={false}
          readonly
        />
      </Box>
      <Typography>
        {data.review}
      </Typography>

      <Typography variant="caption">
        {timeAgo(data.updateTime as number)}
      </Typography>
      <div className={classes.spacer} />
      <Divider />
    </div>
  );
};

export default ReviewItem;