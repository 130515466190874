import React from "react";

import { makeStyles, createStyles } from "@material-ui/styles";
import { Theme, Box, Button, Typography, CircularProgress } from "@material-ui/core";
import { useSelector } from "react-redux";
import { RootState } from "../../store";
import { LeaseOffer } from "leasemojo-common";

import ReviewItem from './ReviewItem';

const useStyles = makeStyles((theme: Theme) =>
  createStyles(
    {
      root: {
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
      },
      ratingList: {
        flex: 1,
        overflow: 'auto',
      },
      progress: {
        display: 'flex',
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center',
      }
    }
  ),
);

interface Props {
  onShowSubmit: () => void;
  alreadyReviewed: boolean;
  offer: LeaseOffer;
}

const ReviewList: React.FC<Props> = ({ onShowSubmit, alreadyReviewed, offer }) => {
  const classes = useStyles();
  const state = useSelector((state: RootState) => state.reviews);

  const reviews = state.listCache[ offer.dealer ] || [];

  return (
    <div className={classes.root}>
      <Box mb={2} display="flex" justifyContent="center">
        <Button variant="contained" color="primary" disableElevation onClick={onShowSubmit}>
          {alreadyReviewed ? 'Update Review' : 'Submit Review'}
        </Button>
      </Box>
      <div className={classes.ratingList}>
        {
          state.loading ? <div className={classes.progress}><CircularProgress /></div> : null
        }
        {
          reviews.map(item => <ReviewItem data={item} key={item.id} />)
        }
      </div>
    </div>
  );
};

export default ReviewList;