import React, { useState } from "react";

import clsx from 'clsx';
import { makeStyles, createStyles } from "@material-ui/styles";
import { Theme, Grid, Box, Button, Tooltip } from "@material-ui/core";
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import OffersIcon from '@material-ui/icons/LocalOffer';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Collapse from '@material-ui/core/Collapse';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

import IconInfo from '@material-ui/icons/InfoOutlined';

import orderBy from 'lodash/orderBy';

import { getExteriorColorIndex, getInteriorColorIndex } from '../../utils/getColorIndex';
import { InquiryData, DownPaymentLabels, CreditScoreLabels, LeaseLengthLabels, LeaseMileageLabels } from "leasemojo-common";

import OfferItem from './OfferItem';
import { useDialog } from "../DialogProvider";
import PickWinnerOffer from "./PickWinnerOffer";

const useStyles = makeStyles((theme: Theme) =>
  createStyles(
    {
      card: {
        width: '100%',
        maxWidth: 400,
        margin: theme.spacing(2)
      },
      cardContent: {
        marginTop: theme.spacing(2),
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      },
      media: {
        height: 0,
        paddingTop: '35%', // 16:9
        backgroundSize: 'contain',
      },
      expand: {
        transform: 'rotate(0deg)',
        marginLeft: 'auto',
        transition: theme.transitions.create('transform', {
          duration: theme.transitions.duration.shortest,
        }),
      },
      expandOpen: {
        transform: 'rotate(180deg)',
      },

      title: {
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        maxWidth: 270,
        overflow: 'hidden',
      },
      offerIcon: {
        marginRight: theme.spacing(1),
      },
      noOffers: {
        padding: theme.spacing(2),
        borderBottom: '1px solid #eee',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      },
      offersList: {
        padding: theme.spacing(2, 2, 1, 2),
        borderBottom: '1px solid #eee',
      }
    }
  ),
);

interface Props {
  data: InquiryData;
  onDelete: (id: string) => void;
}

const InquiryCard: React.FC<Props> = ({ data, onDelete }) => {
  const classes = useStyles();

  const disabled = data.status === 'closed';

  const dialog = useDialog();


  const [ anchorEl, setAnchorEl ] = useState<null | HTMLElement>(null);

  const [ expanded, setExpanded ] = useState(false);

  const toggleExpanded = () => {
    setExpanded(!expanded);
  }

  if (!data.carData || !data.modelData || !data.trimData) {
    return null;
  }

  const handleMoreClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMoreClose = () => {
    setAnchorEl(null);
  };


  const deleteClick = () => {
    handleMoreClose();
    dialog.confirm({
      title: 'Delete inquiry?',
      subtitle: 'You can\'t undo this, and you will lose access to all offers for this car.',
      onConfirm: () => {
        if (data.id) {
          onDelete(data.id);
        }
      }
    })

  }

  const getOffersLabel = () => {
    const count = Object.keys(data.offers).length;
    if (count === 0) {
      return 'No offers yet';
    }
    else if (count === 1) {
      return '1 offer';
    }
    else if (count > 1) {
      return `${count} offers`;
    }
  }

  // const exteriorColorIndex = getExteriorColorIndex(data.exteriorColor, data.trimData.exteriorColors);
  // const interiorColorIndex = getInteriorColorIndex(data.interiorColor, data.trimData.interiorColors);
  const exteriorColorIndex = getExteriorColorIndex(data.exteriorColor || data.trimData.defaultColors.exteriorColorId, data.trimData.exteriorColors);
  const interiorColorIndex = getInteriorColorIndex(data.interiorColor || data.trimData.defaultColors.interiorColorId, data.trimData.interiorColors);

  const imgUrl = data.trimData.exteriorColors[ exteriorColorIndex ].images.lg ? data.trimData.exteriorColors[ exteriorColorIndex ].images.lg : data.modelData.placeholderImage;


  const offers = orderBy(Object.values(data.offers), 'createTime', 'desc');

  let hasSoldOffer = false;
  offers.forEach(offer => {
    if (offer.status === 'sold') {
      hasSoldOffer = true;
    }
  })

  return (
    <Card className={classes.card}>
      {
        offers.length === 0 ? (
          <div className={classes.noOffers}>
            <OffersIcon color="secondary" className={classes.offerIcon} fontSize="small" />
            <Typography variant="h6" align="center">No offers yet</Typography>
          </div>
        ) : (
            <div className={classes.offersList}>
              <Box display="flex" alignItems="center">
                <OffersIcon color="secondary" className={classes.offerIcon} fontSize="small" />
                <Typography variant="caption">{getOffersLabel()}</Typography>
              </Box>
              {
                offers.map(item => (
                  <OfferItem data={item} key={item.id} />
                ))
              }
            </div>
          )
      }
      <CardHeader
        action={
          <IconButton aria-label="settings" onClick={handleMoreClick} disabled={disabled}>
            <MoreVertIcon />
          </IconButton>
        }
        title={`${data.carData.name} ${data.modelData.name}`}
        subheader={`${data.modelData.modelYear} ${data.trimData.name}`}
        classes={{
          subheader: classes.title,
          title: classes.title,
        }}
      />
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleMoreClose}
      >
        <MenuItem onClick={deleteClick}>Delete Inquiry</MenuItem>
      </Menu>
      <CardMedia
        className={classes.media}
        image={process.env.CDN_BASE_URL_CARS + "/" + imgUrl}
        title={data.carData.name}
      />
      {
        hasSoldOffer ? (
          <CardContent className={classes.cardContent}>
            <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center">
              <Box display="flex" alignItems="center">
                <Typography>Please pick a winning offer</Typography>
                <Box ml={1} />
                <Tooltip title="Please choose the offer that you signed.">
                  <IconInfo />
                </Tooltip>
              </Box>
              <Box mt={1} />
              <PickWinnerOffer offers={offers} inquiryId={data.id ? data.id : ''} winningOffer={data.winningOffer} disabled={disabled}/>
            </Box>
          </CardContent>
        ) : null
      }
      <CardActions disableSpacing>
        <IconButton
          className={clsx(classes.expand, {
            [ classes.expandOpen ]: expanded,
          })}
          onClick={toggleExpanded}
          aria-expanded={expanded}
          aria-label="show more"
        >
          <ExpandMoreIcon />
        </IconButton>
      </CardActions>

      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <CardContent>
          <Grid container spacing={2} alignItems="center">

            <Grid item xs={12} sm={6} >
              <Typography variant="caption">Exterior</Typography>
              <Typography variant="body1">{data.trimData.exteriorColors[ exteriorColorIndex ].name}</Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography variant="caption">Interior</Typography>
              <Typography variant="body1">{data.trimData.interiorColors[ interiorColorIndex ].name}</Typography>
            </Grid>

            <Grid item xs={12}>
              <Typography variant="caption">Down payment I'm willing to pay</Typography>
              <Typography variant="body1">{DownPaymentLabels.get(data.downPayment)}</Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="caption">My Credit Score</Typography>
              <Typography variant="body1">{CreditScoreLabels.get(data.creditScore)}</Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="caption">Lease duration that I prefer</Typography>
              <Typography variant="body1">{LeaseLengthLabels.get(data.leaseLength)}</Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="caption">Mileage that I'm planing to drive per year</Typography>
              <Typography variant="body1">{LeaseMileageLabels.get(data.mileage)}</Typography>
            </Grid>

          </Grid>
        </CardContent>
      </Collapse>


    </Card>
  );
};

export default InquiryCard;