import React from "react";

import { makeStyles, createStyles } from "@material-ui/styles";
import {
  Theme,
  Typography,
  Box,
  useMediaQuery,
  Card,
  CardContent,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
} from "@material-ui/core";

import { useTheme } from '@material-ui/core/styles';

import {
  LeaseOffer,
  InquiryData,
  LeaseLengthLabels,
  LeaseMileageLabels,
  getExteriorColorIndex,
  getInteriorColorIndex,
} from "leasemojo-common";

import distanceInWordsToNow from 'date-fns/formatDistanceToNow';

import CarLogo from '../../components/CarImage/CarLogo';
import TrimImage from '../../components/CarImage/TrimImage';

const useStyles = makeStyles((theme: Theme) =>
  createStyles(
    {
      root: {
        marginBottom: theme.spacing(7),
      },
      cardContent: {
        padding: 0,
      },
    }
  ),
);


interface Props {
  offer: LeaseOffer;
  inquiry: InquiryData;
}

const OfferDetails: React.FC<Props> = ({ offer,
  inquiry }) => {
  const classes = useStyles();
  const theme = useTheme();
  const imageSize = useMediaQuery(theme.breakpoints.down('sm')) ? 300 : 500;


  const exteriorColorIndex = getExteriorColorIndex(offer.exteriorColor, offer.trimData ? offer.trimData.exteriorColors : []);
  const interiorColorIndex = getInteriorColorIndex(offer.interiorColor, offer.trimData ? offer.trimData.exteriorColors : []);


  return (
    <div className={classes.root}>
      <Box display="flex" flexDirection="column" alignItems="center" mb={2}>
        <CarLogo car={offer.carData} />
        <Typography variant="h6">{offer.carData?.name}</Typography>
      </Box>
      <Box display="flex" flexDirection="column" alignItems="center" mb={2}>
        <TrimImage trim={offer.trimData} size={imageSize} color={offer.exteriorColor} />
      </Box>
      <Card>
        <CardContent>
          <List>
            <ListItem>
              <ListItemText
                primary="Offer expires in"
              />
              <ListItemSecondaryAction>
                <Typography>{distanceInWordsToNow(offer.expireTime as number)}</Typography>
              </ListItemSecondaryAction>
            </ListItem>
            <ListItem>
              <ListItemText
                primary={`${offer.modelData?.modelYear} ${offer.modelData?.name}`}
                secondary='Model'
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary={`${offer.trimData?.name}`}
                secondary='Trim'
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary="Monthly"
                secondary={offer.taxIncluded ? 'Tax included' : 'Plus tax'}
              />
              <ListItemSecondaryAction>
                <Typography>{`$${offer.monthlyPayment}`}</Typography>
              </ListItemSecondaryAction>
            </ListItem>
            <ListItem>
              <ListItemText
                primary="Lease length"
              />
              <ListItemSecondaryAction>
                <Typography>{`${LeaseLengthLabels.get(offer.leaseLength)}`}</Typography>
              </ListItemSecondaryAction>
            </ListItem>
            <ListItem>
              <ListItemText
                primary="Down payment"
                secondary={offer.downPayment === '0' ? null : 'Includes first month payment and registration'}
              />
              <ListItemSecondaryAction>
                <Typography>{`$${offer.downPayment}`}</Typography>
              </ListItemSecondaryAction>
            </ListItem>
            <ListItem>
              <ListItemText
                primary="Mileage"
              />
              <ListItemSecondaryAction>
                <Typography>{`$${LeaseMileageLabels.get(offer.mileage)}`}</Typography>
              </ListItemSecondaryAction>
            </ListItem>
            <ListItem>
              <ListItemText
                primary="Exterior color"
              />
              <ListItemSecondaryAction>
                <Typography>{offer.trimData?.exteriorColors[ exteriorColorIndex ].name}</Typography>
              </ListItemSecondaryAction>
            </ListItem>
            <ListItem>
              <ListItemText
                primary="Interior color"
              />
              <ListItemSecondaryAction>
                <Typography>{offer.trimData?.interiorColors[ interiorColorIndex ].name}</Typography>
              </ListItemSecondaryAction>
            </ListItem>
          </List>
        </CardContent>
      </Card>
    </div>
  );
};

export default OfferDetails;