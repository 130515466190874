import React from "react"
import { RouteComponentProps } from "@reach/router";

import { makeStyles, createStyles } from "@material-ui/styles"
import { Theme } from "@material-ui/core"
import Paper from "@material-ui/core/Paper"
import Avatar from "@material-ui/core/Avatar"
import Typography from "@material-ui/core/Typography"
import LockOutlinedIcon from "@material-ui/icons/LockOutlined"

import SwipeableViews from 'react-swipeable-views';

import Layout from "../../components/Layout"


import { useSelector } from "react-redux"
import { RootState } from "../../store";

import SignInOptions from './SignInOptions';
import SignInPhone from './SignInPhone';
import SignInPhoneCode from './SignInPhoneCode';
import SignInProfile from './SignInProfile';
import { useQueryParam, BooleanParam } from "use-query-params";

const useStyles = makeStyles((theme: Theme) =>
  createStyles(
    {
      root: {
        textAlign: 'center',
      },
      layoutContent: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      },
      avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
      },
      paper: {
        margin: theme.spacing(2),
        padding: theme.spacing(2, 4),
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      },
    }
  ),
);

const SignInPage: React.FC<RouteComponentProps> = () => {
  const classes = useStyles();
  const state = useSelector((state: RootState) => state.signIn);

  const [ completeProfile ] = useQueryParam('profile', BooleanParam);


  return (
    <Layout contentClassName={classes.layoutContent}>

      <Paper className={classes.paper} elevation={1}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5" paragraph>
          {completeProfile || state.step === 3 ? 'Your name' : 'Sign In'}
        </Typography>

        {
          state.error ? (
            <Typography color="error" align="center">
              {state.error}
            </Typography>
          ) : null
        }

        <SwipeableViews
          index={completeProfile ? 3 : state.step}
          disabled
          containerStyle={{ maxWidth: 280 }}>

          <SignInOptions />
          <SignInPhone />
          <SignInPhoneCode />
          <SignInProfile />

        </SwipeableViews>
      </Paper>

    </Layout>
  )
}


export default SignInPage;