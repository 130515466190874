import React, { useEffect } from "react";

import { makeStyles, createStyles } from "@material-ui/styles";
import {
  Theme,
  useMediaQuery,
  Dialog,
  DialogTitle,
  IconButton,
  DialogContent,
  Slide,
} from "@material-ui/core";

import { useTheme } from '@material-ui/core/styles';

import IconClose from '@material-ui/icons/Close';
import { TransitionProps } from "@material-ui/core/transitions";
import { LeaseOffer } from "leasemojo-common";
import { useQueryParam, BooleanParam } from "use-query-params";
import SwipeableViews from 'react-swipeable-views';

import { actions } from '../../services/reviews';

import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store";

import ReviewList from './ReviewList';
import SubmitReview from './SubmitReview';

const useStyles = makeStyles((theme: Theme) =>
  createStyles(
    {
      root: {
      },
      title: {
        position: 'relative',
        textAlign: 'center',
      },
      closeIcon: {
        position: 'absolute',
        left: theme.spacing(1),
        top: '50%',
        transform: 'translateY(-50%)',
      },
      content: {
        minHeight: 400,
        display: 'flex',
        flexDirection: 'column',
      },
    }
  ),
);

const Transition = React.forwardRef<unknown, TransitionProps>(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

interface Props {
  open: boolean;
  onClose: () => void;
  offer: LeaseOffer;
}

const DealerRatingModal: React.FC<Props> = ({ open, onClose, offer }) => {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const dispatch = useDispatch();

  const reviews = useSelector((state: RootState) => state.reviews);


  const height = isMobile ? window.innerHeight - 100 : 500;

  const [ submitForm = false, setSubmitForm ] = useQueryParam('submit', BooleanParam);

  const dealerId = offer.dealer;

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    if (open) {
      dispatch(actions.loadReview(dealerId));
      if (!submitForm) {
        dispatch(actions.loadReviews(dealerId));
      }
    }
  }, [ dealerId, open, submitForm ]);

  const myReview = reviews.cache[ dealerId ];

  const showSubmit = () => {
    setSubmitForm(true, 'pushIn');
  }
  const hideSubmit = () => {
    setSubmitForm(false, 'replaceIn');
  }


  const submitReviewLabel = myReview ? 'Update Review' : 'Submit Review';


  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="sm"
      fullWidth
      fullScreen={isMobile}
      TransitionComponent={Transition}
    >
      <DialogTitle className={classes.title}>
        {
          submitForm ? submitReviewLabel : 'Dealer Rating'
        }
        <IconButton aria-label="close" className={classes.closeIcon} onClick={onClose}>
          <IconClose />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers className={classes.content}>
        <SwipeableViews index={submitForm ? 1 : 0} disabled containerStyle={{ height }}>

          <ReviewList onShowSubmit={showSubmit} alreadyReviewed={Boolean(myReview)} offer={offer} />

          <SubmitReview offer={offer} onCancel={hideSubmit} currentReview={myReview} />

        </SwipeableViews>

      </DialogContent>
    </Dialog>
  );
};

export default DealerRatingModal;