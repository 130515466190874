import React, { useState } from "react";

import { makeStyles, createStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core";
import TextField from "@material-ui/core/TextField"
import Typography from "@material-ui/core/Typography"

import ButtonLoading from '../../components/ButtonLoading';

import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../store";

import { actions } from '../../services/signIn';
import { trackEvent } from "../../analytics";

const useStyles = makeStyles((theme: Theme) =>
  createStyles(
    {
      root: {
      },
      form: {
        width: "100%", // Fix IE 11 issue.
        marginTop: theme.spacing(1),
      },
      submit: {
        margin: theme.spacing(3, 0, 2),
      },
    }
  ),
);

const SignInPhoneCode: React.FC = () => {
  const state = useSelector((state: RootState) => state.signIn);
  const dispatch = useDispatch();

  const classes = useStyles();

  const [ code, setCode ] = useState('');


  const onSubmitForm = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    event.stopPropagation();

    trackEvent('phoneCodeConfirm');
    dispatch(actions.phoneCodeConfirm(code));
  };

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCode(event.target.value);
  }

  return (
    <React.Fragment>
      <Typography align="center" variant="body1">
        Please enter the confirmation code that we sent you via SMS.
      </Typography>
      <form className={classes.form} noValidate onSubmit={onSubmitForm}>
        <TextField
          variant="outlined"
          margin="normal"
          fullWidth
          label="Verification Code"
          name="verification-code"
          autoComplete="one-time-code"
          value={code}
          onChange={onChange}
          helperText={!state.codeConfirmError ? state.phone : state.codeConfirmError}
          error={state.codeConfirmError != null}
          disabled={state.codeConfirmLoading}
        />

        <ButtonLoading
          type="submit"
          fullWidth
          variant="contained"
          color="secondary"
          className={classes.submit}
          loading={state.codeConfirmLoading}
        >
          Confirm
        </ButtonLoading>
      </form>
    </React.Fragment>
  );
};

export default SignInPhoneCode;