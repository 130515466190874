import React, { useState, useEffect } from "react";

import { makeStyles, createStyles } from "@material-ui/styles";
import { Theme, Typography, TextField, Box, Button } from "@material-ui/core";
import { LeaseOffer, DealerReview } from "leasemojo-common";

import ProgressButton from '../ButtonLoading';

import Rating from '../Rating';
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../store";
import { actions } from '../../services/reviews';

const useStyles = makeStyles((theme: Theme) =>
  createStyles(
    {
      root: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
      },
      form: {
        height: '100%',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
      },
      spacer: {
        height: theme.spacing(2),
        width: theme.spacing(2),
      },
      textField: {
        width: '100%',
      },
    }
  ),
);

interface Props {
  offer: LeaseOffer;
  currentReview?: DealerReview;
  onCancel: () => void;
}

interface SubmitReviewData {
  rating: number | null;
  review: string;
}


const SubmitReview: React.FC<Props> = ({ offer, currentReview, onCancel }) => {
  const classes = useStyles();

  const [ reviewData, setReviewData ] = useState<SubmitReviewData>({
    rating: currentReview ? currentReview.rating : null,
    review: currentReview ? currentReview.review : '',
  })

  const state = useSelector((state: RootState) => state.reviews);
  const dispatch = useDispatch();

  const myReviewId = currentReview?.id;

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    if (currentReview) {
      setReviewData({
        rating: currentReview.rating,
        review: currentReview.review,
      })
    }
  }, [ myReviewId ]);

  const onSubmitReview = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    event.stopPropagation();
    if (offer.id && reviewData.rating !== null && reviewData.rating >= 1 && reviewData.rating <= 5) {
      await dispatch(actions.submitReview({
        id: currentReview && currentReview.id,
        dealer: offer.dealer,
        agent: offer.agent,
        offer: offer.id,
        inquiry: offer.inquiry,
        rating: reviewData.rating as any,
        review: reviewData.review,
      }));

      onCancel();
    }
  }

  return (
    <div className={classes.root}>
      <form onSubmit={onSubmitReview} className={classes.form}>
        <Typography variant="h5" paragraph>{offer.dealerData?.name}</Typography>
        <Rating
          large
          value={reviewData.rating ?? 0}
          precise={false}
          onChange={(newValue) => {
            setReviewData({
              ...reviewData,
              rating: newValue
            })
          }} />
        <div className={classes.spacer} />
        <TextField
          variant="outlined"
          label="Review"
          multiline
          rowsMax={6}
          rows={6}
          value={reviewData.review}
          className={classes.textField}
          onChange={(event) => {
            setReviewData({
              ...reviewData,
              review: event.target.value,
            })
          }}
        />
        <div className={classes.spacer} />
        <Box display="flex">
          <Button onClick={onCancel} variant="contained" color="primary" disableElevation>Cancel</Button>
          <div className={classes.spacer} />
          <ProgressButton
            variant="contained"
            color="primary"
            disableElevation
            disabled={reviewData.rating === null || state.submitPending}
            loading={state.submitPending}
            type="submit">
            {currentReview ? 'Update' : 'Submit'}
          </ProgressButton>
        </Box>
      </form>
    </div>
  );
};

export default SubmitReview;