import React, { useEffect } from "react";

import { makeStyles, createStyles } from "@material-ui/styles";
import { Theme, Typography, Toolbar, CircularProgress, Button, Divider } from "@material-ui/core";

import Layout from '../components/Layout';

import InquiryCard from "../components/InquiryCard/InquiryCard";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../store";
import { actions } from '../services/inquiries';
import { actions as userActions } from '../services/user';
import { useQueryParam, BooleanParam } from "use-query-params";
import { useDialog } from "../components/DialogProvider";

const useStyles = makeStyles((theme: Theme) =>
  createStyles(
    {
      root: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        alignItems: 'flex-start',
        justifyContent: 'center',
      },
      toolbar: {
        borderBottom: '1px solid #eee',
      }
    }
  ),
);

const MyOffers: React.FC = () => {
  const classes = useStyles();
  const state = useSelector((state: RootState) => state.inquiries);
  const dispatch = useDispatch();

  const [ askPermission ] = useQueryParam('permission', BooleanParam);
  const dialog = useDialog();

  const onConfirm = () => {
    dispatch(userActions.requestPushPermission());
  }

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    const iOS = typeof window !== 'undefined' ? !!navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform) : false;

    if (iOS) {
      return;
    }

    if (askPermission) {
      if ('Notification' in window && Notification.permission === 'granted') {
        dispatch(userActions.requestPushPermission());
      }
      else {
        dialog.alert({
          title: 'Allow push notifications',
          subtitle: 'We will send you a notification when you receive a new offer.',
          onConfirm,
        });
      }
    }
  }, [ askPermission ])

  const deleteInquiry = (id: string) => {
    dispatch(actions.deleteInquiry(id));
  }

  const activeList = state.inquiries.filter(data => data.status === 'active');
  const closedList = state.inquiries.filter(data => data.status === 'closed');

  return (
    <Layout>
      <Toolbar>
        <Typography variant="h6">My Inquiries</Typography>
      </Toolbar>
      <div className={classes.root}>
        {state.loading ? <CircularProgress /> : activeList.map(item => <InquiryCard key={item.id} data={item} onDelete={deleteInquiry} />)}
      </div>

      {
        closedList.length > 0 ? (
          <React.Fragment>
            <Divider />
            <Toolbar>
              <Typography variant="h6">Closed inquiries</Typography>
            </Toolbar>
            <div className={classes.root}>
              {closedList.map(item => <InquiryCard key={item.id} data={item} onDelete={deleteInquiry} />)}
            </div>
          </React.Fragment>
        ) : null
      }
    </Layout>
  );
};

export default MyOffers;