import React from "react";

import { makeStyles, createStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import ButtonLoading from "../../components/ButtonLoading";
import InputMask from 'react-input-mask';

import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../store";

import { actions } from '../../services/signIn';
import { trackEvent } from "../../analytics";



const useStyles = makeStyles((theme: Theme) =>
  createStyles(
    {
      form: {
        width: "100%", // Fix IE 11 issue.
        marginTop: theme.spacing(1),
      },
      submit: {
        margin: theme.spacing(3, 0, 2),
      },
    }
  ),
);

const PhoneInput = ({ ...props }) => {
  return <InputMask {...props} mask="+1 999 999 9999" />
}


const SignInPhone: React.FC = () => {
  const classes = useStyles();
  const state = useSelector((state: RootState) => state.signIn);
  const dispatch = useDispatch();


  const onSubmitForm = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    event.stopPropagation();
    trackEvent('phoneCodeSend');
    dispatch(actions.phoneCodeSend("recaptcha"));
  };

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(actions.setPhone(event.target.value));
  }

  return (
    <React.Fragment>
      <Typography align="center" variant="body1">
        Please enter your phone number to continue.
      </Typography>
      <form className={classes.form} noValidate onSubmit={onSubmitForm}>

        <TextField
          variant="outlined"
          margin="normal"
          fullWidth
          label="Phone number"
          name="phone-number"
          autoComplete="phone"
          onChange={onChange}
          value={state.phone}
          error={state.codeSendError != null}
          helperText={state.codeSendError}
          disabled={state.codeSendLoading}
          InputProps={{
            inputComponent: PhoneInput as any,
          }}
        />

        <ButtonLoading
          type="submit"
          fullWidth
          variant="contained"
          color="secondary"
          className={classes.submit}
          id="verify-phone-button"
          loading={state.codeSendLoading}
        >
          Continue
        </ButtonLoading>
      </form>
      <Typography variant="caption" align="center" component="p">By continuing you agree to receive SMS with confirmation code</Typography>
    </React.Fragment>
  );
};

export default SignInPhone;