import React, { useEffect, useState } from "react";
import { isEmail } from 'validator';
import { makeStyles, createStyles } from "@material-ui/styles";
import { Theme, Button, Paper, Typography, TextField, Box } from "@material-ui/core";
import InputMask from 'react-input-mask';
import ProgressButton from '../components/ButtonLoading';

import Layout from '../components/Layout';
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../store";
import { actions } from '../services/user';
import { useDialog } from "../components/DialogProvider";

const useStyles = makeStyles((theme: Theme) =>
  createStyles(
    {
      root: {
        maxWidth: 400,
        marginTop: theme.spacing(5),
        marginLeft: 'auto',
        marginRight: 'auto',
        padding: theme.spacing(0, 2),
      },
      paper: {
        padding: theme.spacing(2, 4),
      },
      bottom: {
        textAlign: 'center',
        marginTop: theme.spacing(4),
      },
    }
  ),
);

const PhoneInput = ({ ...props }) => {
  return <InputMask {...props} mask="+1 999 999 9999" />
}

const MyAccount: React.FC = () => {
  const classes = useStyles();
  const state = useSelector((state: RootState) => state.user);
  const dispatch = useDispatch();
  const dialog = useDialog();

  const [ formState, setFormState ] = useState({
    email: state.user ? state.user.email : '',
    firstName: state.user ? state.user.firstName : '',
    lastName: state.user ? state.user.lastName : '',
  });

  const [ errors, setErrors ] = useState({
    email: '',
    firstName: '',
    lastName: '',
  });

  const handleChange = (name: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
    setFormState({ ...formState, [ name ]: event.target.value });
  };

  const validate = () => {
    const _err = {
      email: '',
      firstName: '',
      lastName: '',
    };
    let valid = true;

    if (!formState.email || !isEmail(formState.email)) {
      _err.email = 'Invalid email';
      valid = false;
    }
    if (!formState.firstName || formState.firstName.trim() === '') {
      _err.firstName = 'First name is required';
      valid = false;
    }

    if (!formState.lastName || formState.lastName.trim() === '') {
      _err.firstName = 'Last name is required';
      valid = false;
    }

    setErrors(_err);
    return valid;
  }

  const onSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    event.stopPropagation();

    if (!validate()) {
      return;
    }


    dispatch(actions.updateProfile({
      firstName: formState.firstName,
      lastName: formState.lastName,
      email: formState.email || '',
    }));

  }

  const deleteAccount = () => {
    dialog.confirm({
      title: 'Are you sure?',
      subtitle: 'You can\'t undo this, and you will lose all your offers and chat messages.',
      onConfirm: () => {
        dispatch(actions.deleteAccount());
      }
    })
  }

  if (!state.user) {
    return null;
  }


  return (
    <Layout contentClassName={classes.root}>
      <Paper className={classes.paper}>
        <Typography variant="h5" align="center" paragraph>My Profile</Typography>
        <form noValidate autoComplete="off" onSubmit={onSubmit}>

          {
            state.user.phone ? (
              <TextField
                fullWidth
                id="standard-basic"
                label="Phone number"
                margin="normal"
                variant="outlined"
                value={state.user.phone}
                disabled={true}
                InputProps={{
                  inputComponent: PhoneInput as any,
                }}
              />
            ) : null
          }

          <TextField
            fullWidth
            id="standard-basic"
            label="First Name"
            margin="normal"
            variant="outlined"
            value={formState.firstName}
            disabled={state.profileUpdateLoading}
            onChange={handleChange('firstName')}
            error={Boolean(errors.firstName)}
            helperText={errors.firstName ? errors.firstName : null}
          />

          <TextField
            fullWidth
            id="standard-basic"
            label="Last Name"
            margin="normal"
            variant="outlined"
            value={formState.lastName}
            disabled={state.profileUpdateLoading}
            onChange={handleChange('lastName')}
            error={Boolean(errors.lastName)}
            helperText={errors.lastName ? errors.lastName : null}
          />

          <TextField
            fullWidth
            id="standard-basic"
            label="Email"
            margin="normal"
            variant="outlined"
            value={formState.email}
            disabled={state.profileUpdateLoading}
            onChange={handleChange('email')}
            error={Boolean(errors.email)}
            helperText={errors.email ? errors.email : null}
          />

          <div className={classes.bottom}>
            <ProgressButton
              type="submit"
              variant="contained"
              color="primary"
              loading={state.profileUpdateLoading}
            >
              Save
            </ProgressButton>
          </div>
        </form>
      </Paper>
      <Box display="flex" justifyContent="center" mt={5}>
        <ProgressButton loading={state.deleteAccountLoading} variant="outlined" onClick={deleteAccount}>Delete my Account</ProgressButton>
      </Box>
    </Layout>
  );
};

export default MyAccount;