import React from "react";

import { makeStyles, createStyles } from "@material-ui/styles";
import {
  Theme,
  useMediaQuery,
  Dialog,
  DialogTitle,
  IconButton,
  DialogContent,
  Typography,
  List,
  ListItemText,
  ListItem,
  ListItemAvatar,
  DialogActions,
  Button,
} from "@material-ui/core";

import { useTheme } from '@material-ui/core/styles';

import IconClose from '@material-ui/icons/Close';
import IconPhone from '@material-ui/icons/Phone';
import IconEmail from '@material-ui/icons/Email';
import IconAddress from '@material-ui/icons/NearMe';
import { Dealer } from "leasemojo-common";

import InstagramIcon from '../../icons/instagram_color.svg';
import FacebookIcon from '../../icons/facebook_color.svg';
import YoutubeIcon from '../../icons/youtube_color.svg';
import TwitterIcon from '../../icons/facebook_color.svg';

const useStyles = makeStyles((theme: Theme) =>
  createStyles(
    {
      root: {
      },
      title: {
        position: 'relative',
        textAlign: 'center',
      },
      closeIcon: {
        position: 'absolute',
        left: theme.spacing(1),
        top: '50%',
        transform: 'translateY(-50%)',
      },
      content: {
        padding: 0,
        display: 'flex',
        flexDirection: 'column',
      },
      list: {
        flex: 1,
      },
      bottom: {
        display: 'flex',
        justifyContent: 'center',
        borderTop: '1px solid ' + theme.palette.divider,
      },
      socialIcon: {
        width: 25,
        height: 25,
      },
      iconContainer: {
        display: 'flex',
      }
    }
  ),
);

interface Props {
  open: boolean;
  onClose: () => void;
  dealer: Dealer;
}

const ContactDealerModal: React.FC<Props> = ({ open, onClose, dealer }) => {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const iOS = typeof window !== 'undefined' ? !!navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform) : false;


  const addressQuery = `${dealer.address?.address1} ${dealer.address?.address2 ? `, ${dealer.address?.address2}` : null}, ${dealer.address?.city}, ${dealer.address?.state}, ${dealer.address?.zip}`;

  const addressLink = iOS && isMobile ? `http://maps.apple.com/?address=${encodeURI(addressQuery)}` : `https://www.google.com/maps/place/${encodeURI(addressQuery)}`;

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="sm"
      fullWidth
      fullScreen={isMobile}
    >
      <DialogTitle className={classes.title}>
        {dealer.name}
        <IconButton aria-label="close" className={classes.closeIcon} onClick={onClose}>
          <IconClose />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers className={classes.content}>
        <List className={classes.list}>

          <ListItem button component="a" href={`tel:${dealer.phone}`} target="_blank">
            <ListItemAvatar>
              <IconPhone />
            </ListItemAvatar>
            <ListItemText primary={dealer.phone} secondary="Phone" />
          </ListItem>

          <ListItem button component="a" href={`mailto:${dealer.email}`} target="_blank">
            <ListItemAvatar>
              <IconEmail />
            </ListItemAvatar>
            <ListItemText primary={dealer.email} secondary="Email" />
          </ListItem>

          {
            dealer.address ? (
              <ListItem button component="a" href={`${addressLink}`} target="_blank">
                <ListItemAvatar>
                  <IconAddress />
                </ListItemAvatar>
                <Typography>
                  {dealer.address?.address1} {dealer.address?.address2 ? `, ${dealer.address?.address2}` : null}
                  <br />
                  {dealer.address?.city}, {dealer.address?.state}, {dealer.address?.zip}
                </Typography>
              </ListItem>
            ) : null
          }

          {
            dealer.social?.facebook ? (
              <ListItem button component="a" href={`//www.facebook.com/${dealer.social.facebook}`} target="_blank">
                <ListItemAvatar className={classes.iconContainer}>
                  <FacebookIcon className={classes.socialIcon} />
                </ListItemAvatar>
                <ListItemText primary={`@${dealer.social.facebook}`} />
              </ListItem>
            ) : null
          }
          {
            dealer.social?.instagram ? (
              <ListItem button component="a" href={`//www.instagram.com/${dealer.social.instagram}`} target="_blank">
                <ListItemAvatar className={classes.iconContainer}>
                  <InstagramIcon className={classes.socialIcon} />
                </ListItemAvatar>
                <ListItemText primary={`@${dealer.social.instagram}`} />
              </ListItem>
            ) : null
          }
          {
            dealer.social?.youtube ? (
              <ListItem button component="a" href={`//www.youtube.com/channel/${dealer.social.youtube}`} target="_blank">
                <ListItemAvatar className={classes.iconContainer}>
                  <YoutubeIcon className={classes.socialIcon} />
                </ListItemAvatar>
                <ListItemText primary="Channel" primaryTypographyProps={{
                  noWrap: true,
                  component: 'p',
                } as any} />
              </ListItem>
            ) : null
          }
          {
            dealer.social?.twitter ? (
              <ListItem button component="a" href={`//twitter.com/${dealer.social.twitter}`} target="_blank">
                <ListItemAvatar className={classes.iconContainer}>
                  <TwitterIcon className={classes.socialIcon} />
                </ListItemAvatar>
                <ListItemText primary={`@${dealer.social.twitter}`} />
              </ListItem>
            ) : null
          }

        </List>
        <DialogActions className={classes.bottom}>
          <Button onClick={onClose}>Close</Button>
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
};

export default ContactDealerModal;