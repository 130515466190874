import React from "react";

import { makeStyles, createStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core";

import { actions } from '../../services/signIn';

import AuthButton from '../../components/AuthButton';
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store";
import { trackEvent } from "../../analytics";

const useStyles = makeStyles((theme: Theme) =>
  createStyles(
    {
      root: {
        // display: 'flex',
        // flexDirection: 'column',
        // alignItems: 'center',
        // justifyContent: 'center',
        // height: '100%',
      },
      spacer: {
        height: theme.spacing(2),
      },
    }
  ),
);

const SignInOptions: React.FC = () => {
  const classes = useStyles();

  const dispatch = useDispatch();

  const onPhoneClick = () => {
    trackEvent('login', { method: 'phone' });
    dispatch(actions.signInPhone());
  }

  const onFacebookClick = () => {
    trackEvent('login', { method: 'facebook.com' });
    dispatch(actions.signInFacebook());
  }

  const onGoogleClick = () => {
    trackEvent('login', { method: 'google.com' });
    dispatch(actions.signInGoogle());
  }



  const state = useSelector((state: RootState) => state.signIn);

  return (
    <div className={classes.root}>
      <AuthButton type="phone" onClick={onPhoneClick} />
      <div className={classes.spacer} />
      <AuthButton type="facebook.com" onClick={onFacebookClick} loading={state.inProgress === 'facebook.com'} />
      <div className={classes.spacer} />
      <AuthButton type="google.com" onClick={onGoogleClick} loading={state.inProgress === 'google.com'} />
    </div>
  );
};

export default SignInOptions;