import React, { useState } from "react";

import { makeStyles, createStyles } from "@material-ui/styles";
import { Theme, Typography, TextField } from "@material-ui/core";
import ButtonLoading from "../../components/ButtonLoading";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../store";

import { actions } from '../../services/signIn';

const useStyles = makeStyles((theme: Theme) =>
  createStyles(
    {
      root: {
      },
      form: {
        width: "100%", // Fix IE 11 issue.
        marginTop: theme.spacing(1),
      },
      submit: {
        margin: theme.spacing(3, 0, 2),
      },
    }
  ),
);

const SignInProfile: React.FC = () => {
  const classes = useStyles();
  const state = useSelector((state: RootState) => ({
    signIn: state.signIn,
    user: state.user,
  }));
  const dispatch = useDispatch();

  const [ formState, setFormState ] = useState({
    firstName: state.user.user?.firstName || '',
    lastName: state.user.user?.lastName || '',
  })

  const [ errors, setErrors ] = useState({
    firstName: false,
    lastName: false,
  });


  const handleChange = (name: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
    setFormState({
      ...formState,
      [ name ]: event.target.value
    });
  };

  const onSubmitForm = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    event.stopPropagation();
    setErrors({ firstName: false, lastName: false });

    if (!formState.firstName || formState.firstName.trim() === '') {
      setErrors({
        ...errors,
        firstName: true
      })
      return;
    }
    if (!formState.lastName || formState.lastName.trim() === '') {
      setErrors({
        ...errors,
        lastName: true
      })
      return;
    }

    dispatch(actions.completeProfile({ ...formState }));
  };

  return (
    <React.Fragment>
      <Typography align="center" variant="body1">
        Looks like you are signing in for the first time. Please type in your name.
      </Typography>

      <form className={classes.form} noValidate onSubmit={onSubmitForm}>

        <TextField
          variant="outlined"
          margin="normal"
          fullWidth
          label="First name"
          name="first-name"
          autoComplete="first-name"
          value={formState.firstName}
          onChange={handleChange('firstName')}
          error={errors.firstName}
          helperText={errors.firstName ? 'First name required' : ''}
        />

        <TextField
          variant="outlined"
          margin="normal"
          fullWidth
          label="Last name"
          name="last-name"
          autoComplete="last-name"
          value={formState.lastName}
          onChange={handleChange('lastName')}
          error={errors.lastName}
          helperText={errors.lastName ? 'Last name required' : ''}
        />

        <ButtonLoading
          type="submit"
          fullWidth
          variant="contained"
          color="secondary"
          className={classes.submit}
          loading={state.signIn.profileCompleteLoading}
        >
          Submit
        </ButtonLoading>

      </form>
    </React.Fragment>
  );
};

export default SignInProfile;